<template>
  <div>
    <el-tabs class="tabs" v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="商品列表" name="first">
        <productList v-if="activeName == 'first'"  :jurisdiction="jurisdiction"/>
      </el-tab-pane>

      <el-tab-pane label="商品分类" name="second">
        <classifyList v-if="activeName == 'second'" :jurisdiction="jurisdiction"/>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import productList from "./components/productList.vue";
import classifyList from "./components/classifyList.vue";
import { mapState } from 'vuex';

export default {
  name: "management",
  components: {
    productList,
    classifyList,
  },

  data() {
    return {
      activeName: "first",
      jurisdiction:false,
    };
  },
  computed: {
    ...mapState(['routerArr']),

  },
  created(){
    if(this.$route.query.name){
      this.activeName = this.$route.query.name
    }
  },
  watch:{
    routerArr(val){
      this.jurisdiction = val.includes("040")
    },
  },
  methods: {
    handleClick(tab) {
      const { name } = tab;
      this.activeName = name;
    },
  },
};
</script>

<style lang="scss" scoped></style>
